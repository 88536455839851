.NotificationContainer {
    position: absolute;
    z-index: 99999;
}

@media only screen and (max-width: 600px) {
    .NotificationContainer {
        width: 90vw;
        bottom: 5vw;
        right: 5vw;
    }
}

@media only screen and (min-width: 600px) {
    .NotificationContainer {
        width: 400px;
        bottom: 32px;
        right: 32px;
    }
}

.Notification {
    margin-top: 16px;
    background-color: white;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.8); 
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.8);
    padding: 16px;
    text-align: left;
    border-radius: 2px;
}

.NotificationError {
    border-left: 6px solid red;
}

.NotificationWarning {
    border-left: 6px solid yellow;
}

.NotificationInfo {
    border-left: 6px solid green;
}

.CloseIcon {
    float: right;
}