.Dropdown {
    font-weight: 600;
    color: #a0a0a0;
    width: 100%;
    padding: 8px;
    border: 1px solid #a0a0a0;
    border-radius: 4px;
    background-color: white;
    margin-bottom: 16px;
}

@media only screen and (max-width: 600px) {
    .Dropdown {
        font-size: 12pt;
    }
}

@media only screen and (min-width: 600px) {
    .Dropdown {
        font-size: 16pt;
    }
}