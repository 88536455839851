@media only screen and (min-width: 600px)
{
    .CreateLinkForm {
        width: 80%;
        margin: auto;
    }
}

.CopiedToClipboardNotice {
    font-style: italic;
    color: #a0a0a0;
}

.ValidationMessages {
    text-align: left;
    list-style-type: none;
    padding: 0;
}

.ValidationMessages li {
    line-height: 16pt;
}

.ErrorIcon {
    color: rgb(119, 0, 0);
}