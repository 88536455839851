body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  body {
    padding: 16px 0 0 0;
  }

  .contentPane {
    width: 100%;
  }

  h1 {
    font-size: 28pt;
    line-height: 48pt;
  }
}

@media only screen and (min-width: 600px) {
  
  body {
    padding: 32px 0 0 0;
  }

  .contentPane {
    width: 1000px;
    max-width: 80%;
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.5); 
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.5);
  }

  h1 {
    font-size: 64pt;
    line-height: 112pt;
  }
}

.contentPane {
  background-color: white;
  border-radius: 4px;
  text-align: center;
  margin: auto;
}

#footer {
  margin: 32px auto auto auto;
  text-align: center;
}

.copyright {
  font-size: 6pt;
}

h1 {
  font-family: "IM Fell DW";
  font-weight: 600;
  color: #12123b;
  margin: 0;
}

hr {
  border-color: #12123b;
  border-style: dashed;
  border-width: 2px;
  width: 85%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "IM Fell DW";
  src: url("./fonts/IMFellDWPica-Regular.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "IM Fell DW";
  src: url("./fonts/IMFellDWPica-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300; /* LIGHT */
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300; /* LIGHT */
  font-style: italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400; /* REGULAR */
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: 400; /* REGULAR */
  font-style: italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 600; /* BOLD */
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("./fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: 600; /* BOLD */
  font-style: italic;
}