.OldschoolStatsRenderer {
    margin: auto;
}

.OldSchoolStatsRendererLCell {
    text-align: left;
}

.OldSchoolStatsRendererRCell {
    text-align: right;
}

.IndividualResults {
    font-size: 24pt;
}