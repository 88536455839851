.FloatingActionButton {
    background-color: #af8300;
    position: fixed;
    bottom: 32px;
    right: 32px;
    z-index: 999;
    -webkit-box-shadow: 0px 0px 10px 7px rgba(0,0,0,0.66); 
    box-shadow: 0px 0px 10px 7px rgba(0,0,0,0.66);
    cursor: pointer;
    color: white;
    vertical-align: middle;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .FloatingActionButton{
        width: 64px;
        height: 64px;
        border-radius: 32px;
        font-size: 24pt;
        line-height: 48pt;
    }
}

@media only screen and (min-width: 600px) {
    .FloatingActionButton{
        width: 96px;
        height: 96px;
        border-radius: 48px;
        font-size: 24pt;
        line-height: 72pt;
    }
}