.ButtonWrapper {
    width: 100%;
    text-align: right;
}

.Button {
    display: inline-block;
    background-color: #7e3900;
    color: white;
    font-family: "IM Fell DW";
    font-weight: 600;
    font-size: 16pt;
    padding: 8px 32px 8px 32px;
    line-height: 24pt;
    border-radius: 8px;
    border: 0px;
    width: fit-content;
    cursor: pointer
}

.Button:hover {
    background-color: #b15000;
}

.ButtonDisabled, .ButtonDisabled:hover {
    background-color: #a0a0a0;
    cursor: not-allowed;
}