.Option {
    font-family: "OpenSans";
    font-weight: 600;
    color: black;
}

@media only screen and (max-width: 600px) {
    .Option {
        font-size: 12pt;
    }
}

@media only screen and (min-width: 600px) {
    .Option {
        font-size: 16pt;
    }
}