.DiceExpressionResultRenderer {

}

.GrandTotal {
    font-size: 30pt;
    font-weight: 600;
}

.Expression {
    font-size: 16pt;
    font-weight: 400;
}