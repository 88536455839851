.TextFieldWrapper {
    display: flex;
    margin-bottom: 16px;
    width: 100%;
    padding: 8px;
    border: 1px solid #a0a0a0;
    border-radius: 4px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.TextField {
    flex: 1;
    font-family: "OpenSans";
    font-weight: 600;
    border: none;
    outline: none;
    width: auto;
    overflow: hidden;
}

input[readonly=true] {
    color: #a0a0a0 !important;
}

.Icon {
    color: #a0a0a0;
    font-size: 22pt;
    line-height: 20pt;
}

.TextField::placeholder {
    color: #a0a0a0;
}

.TextFieldInvalid {
    border: 2px solid rgb(119, 0, 0);
}

@media only screen and (max-width: 600px) {
    .TextField {
        font-size: 12pt;
    }
}

@media only screen and (min-width: 600px) {
    .TextField {
        font-size: 16pt;
    }
}