.DiceResultsWrapper {
    font-family: "OpenSans";
    font-size: 16pt;
    font-weight: 400;
    padding-bottom: 32px;
}

.RecipientName {
    color: rgb(5, 99, 94);
    font-weight: 600;
}

.RequesterName {
    color: rgb(58, 9, 85);
    font-weight: 600;
}