.ModalPane {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0,0,0,.5);
    text-align: center;
    vertical-align: middle;
}

.ModalDialog {
    position: relative;
    top: 25vh;
    width: 60vw;
    max-width: 1000px;
    margin: auto;
    padding: 32px;
    background-color: white;
    font-family: 'OpenSans';
    font-size: 14pt;
    font-weight: 400;
}